<template>
	<Page name="craqueur" 
	title="Le craqueur<br> catalytique (FCC)"
	implementation="1982"
	tonnesHandled="300"
	processingTemperature="525"
	abstract="<p>Cette unité transforme des produits lourds (base fuel) en produits légers (essence, gasoil, GPL). Grâce à l’action d’un catalyseur et de la chaleur (craquage catalytique), il permet d’obtenir toute la gamme des hydrocarbures : Propane / Propylène (plastique), Butane, Essence, Gazole, Fuels, Bitumes.</p>"
	animation="static/animation/FCC.json">
	<picture slot="thumbnail">
		<img class="lazy"
		sizes="(max-width: 480px) 100vw, 480px"
		data-srcset="
		img/secteur/FCC_xuv51i_c_scale_w_350.jpg 350w,
		img/secteur/FCC_xuv51i_c_scale_w_480.jpg 480w"
		data-src="img/secteur/FCC_xuv51i_c_scale_w_480.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Page>
</template>

<script>
	export default {
		name: 'craqueur',
		components: {
			Page: () => import('@/components/Page.vue'),
		},
	}
</script>
